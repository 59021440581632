import dob from '../images/dob.jpg';
import san from '../images/san.jpg';
import def from '../images/default.png';

const img_map = {
    'dob' : dob,
    'san' : san,
    'default': def,
}

export default img_map;