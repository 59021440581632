import {useState} from 'react';

export default function TerminalForm() {
    const [input, setInput] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [update, setUpdate] = useState('')

    const commands = {
        'help' : 'Current commands: help, technologies, bio, clear',
        'technologies' : 'MERN, Shopify, Wordpress, Python',
        'easter egg': "Coming soon!",
        'bio' : "I grew up in Helensburgh, Scotland. As a kid I was always interested in computers, having built my first gaming pc with my stepdad in 2011. I also built the pc that ran the front end of my mum’s toy shop. At the age of 13 I visited CERN with my dad and brother, this visit later inspired me to enrol in the University of Strathclyde for an integrated master’s degree in Physics. During my time at university I discovered the field of machine learning. The thought of computational algorithms processing and learning from data to solve real world problems had me instantly hooked. In the summer after my undegraduate thesis I was awarded a Carnegie Vacation Scholarship to carry out a research internship at the intersection of theoretical quantum optics and machine learning. This project was so interesting to me that I decided to enrol in a machine learning module from the computer science department the next year. Aside from my studies I am an avid rock climber (bouldering in particular), I love to get out onto the crags with my friends during summer. During winter I’m a regular at TCA Glasgow and I also love to go to the gym.",
    };

    const failure = "This command does not exist, please enter `help` for a list of commands.";

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        
        if (input in commands) {
            var command = commands[input]
            setUpdate(command)
        } else if (input === "clear") {
            setUpdate('Console cleared. Enter new command.')
        } else {
            setUpdate(failure);
        }

        setInput("");

    }

    if (submitted) {
        return (
            <div className="terminal-component">
                <p>&gt; {update}</p>
                <div className="terminal-input-div">
                <form className="terminal-form" onSubmit={e => {handleSubmit(e)}}>
                    &gt; <input type="text" onChange={e => setInput(e.target.value)} value={input}/>
                    <input type="submit" hidden/>
                </form>
                </div>
            </div>

        );
    } else {
        return (
                <div className="terminal-input-div">
                    <form className="terminal-form" onSubmit={e => {handleSubmit(e)}}>
                        &gt; <input type="text" onChange={e => setInput(e.target.value)} value={input} />
                        <input type="submit" hidden/>
                    </form>
                </div>
        );
    }
}