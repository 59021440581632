import './App.css';
import HomePage from './pages/main';
import Contact from './pages/contact';
import Projects from './pages/projects';
import RadialCursorAura from './components/aura';
import NavBar from './components/navbar';
import Banner from './components/banner';
import TerminalCommands from './components/TerminalCommands';
import {useState} from 'react';


function App() {
  const [isShown, setIsShown] = useState(false);

  const toggle = (isShown) => {
    setIsShown((isShown) => !isShown);
  }

  return (
    <div className="full">
        <RadialCursorAura />
        <NavBar toggle={toggle} />
        {isShown && <TerminalCommands />}
        
        <HomePage />
        <Projects />
        <Contact />

    </div>
  );
}

export default App;