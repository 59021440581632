import '../styles/navbar.css';


export default function NavBar({ toggle }) {

    return (
        <div className="nav-wrapper">
            <div className="navbar">
                <div className="nav-container"></div>
                <div className="nav-container"></div>
                <div className="nav-container">
                    <a href="#projects">Portfolio</a>
                    <a href="#contact-container">Contact</a>
                    <button id="terminal-link" onClick={toggle}>Terminal</button>
                </div>
            </div>
        </div>
    );

}