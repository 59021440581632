const dob = "Diary of a Business is an online blog focused on entrepeneurship and chocolate making. Follow along as multi-award winning entrepeneurs detail the process of building a business from the ground up! The site was built using WordPress, as the owner wanted something modern and minimalist but also easy to manage herself. The site is fully SEO optimised and features a variety of components including hero sections, FAQ, contact forms and blog posts."

const san = "Science and Nature is an online retailer focused on the wholesale of high quality science gifts and kits for people of all age groups. This e-commerce store was built with full Shopify integration, allowing for a combination of smooth operation and high-performance analytics. The site has been developed with solid SEO principles and client only access. I have attached screenshots of the site, but the site itself is accessible to clients only."

const qroad = "Quantum Roadmap is a personal project that I'm very proud of. I'm a recent university graduate with a first class master's degree specialising in quantum physics. The purpose of this site is to provide a platform for people to learn theoretical quantum physics for free. The site provides progress trackers and links to free online lecture material, in order to give self studying students some structure to their learning."

const descriptions = {
    'dob' : dob,
    'san' : san,
    'qroad' : qroad,
}

export default descriptions;