import ContactForm from '../components/contactForm';
import '../styles/contact.css';

export default function Contact() {
    return (
        <div id="contact-container">
            <ContactForm />
            <div id="footer">
                <a href="https://github.com/RobHam99">Designed and built by Robbie Hamilton, 2023.</a>
            </div>
        </div>
    );
}