import '../styles/projects.css';
import Project from '../components/project';
import descriptions from '../content/descriptions';

export default function Projects() {
    return (
        <div id="project-container">
            <div id="projects">
                <Project title={"Diary of a Business"} url={'https://diaryofabusiness.com'} path={"dob"} description={descriptions['dob']} stack={["Business", "WordPress", "SEO"]}/>
                <Project title={"Science & Nature Wholesale"} url = {'https://scienceandnature.co.uk'} path={"san"} description={descriptions['san']} stack={["E-commerce", "Shopify", "SEO"]}/>
                <Project title={"Quantum Roadmap"} url = {'https://physicsroadmap.com'} path={"default"} description={descriptions['qroad']} stack={["Education", "React-JS", "MongoDB"]}/>
            </div>
        </div>
    );
}