import '../styles/projects.css';
import img_map from '../images/images';


export default function Project(props) {
    let title = props.title
    let img_url = props.path
    let desc = props.description
    let stack = props.stack
    let url = props.url

    return (
        <div className="project">
            <img class="image" src={ img_map[img_url] }/>
            <div className="project-contents">
                <h2 className="project-title"><a href={ url }>{ title }</a></h2>
                <div className="project-description">{ desc }</div>
                <div className="project-stack">
                    <p>{stack[0]}</p>
                    <p>{stack[1]}</p>
                    <p>{stack[2]}</p>
                </div>
            </div>
        </div>
    );
}