import '../styles/terminal.css';
import TerminalForm from './terminalForm';

function createDate() {
    var date = new Date();
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    return (dd + "-" + mm + "-" + yyyy);
}

export default function Terminal() {
    var newDate = createDate()
    return (
        <div id="terminal">
            <div id="terminal-header">
                <div id="terminal-buttons">
                    <span id="red"></span>
                    <span id="yellow"></span>
                    <span id="green"></span>
                </div>
                <div id="terminal-title">Terminal</div>
                <div id="terminal-right"></div>

            </div>
            <div id="terminal-body">
                <p id="last-login">Last login: {newDate}</p>
                <TerminalForm />
            </div>
        </div>
    );
}