import Terminal from '../components/terminal';
import '../styles/main.css';

export default function HomePage() {
    return (
        <main>
            <div className="container">
                <div className="left">
                    <div id="whitespace">

                    </div>
                    <div id="details">
                        <h1 id="name">Robbie Hamilton</h1>
                        <h2 id="title">Full-Stack Web Developer</h2>
                        <p id="description">Hi there &#128075; welcome to my site! I'm a website developer specialising in two areas:</p>
                        <ul>
                            <li><b>E-commerce</b>: Bespoke online stores tailored to individuals and small businesses. </li>
                            <li><b>Analytics</b>: High performance analytics dashboards using MERN and machine learning.</li>
                        </ul>
                        <div className="button-wrapper">
                            <a href="#projects">Portfolio</a>
                        </div>
                    </div>
                    <div id="socials">
                        <div className="social-box">
                            <a href="https://github.com/RobHam99" target="_blank"><img src={require('../images/github.png')} alt="GitHub Icon" width="35" height ="35"/></a>
                            <a href="https://www.linkedin.com/in/robbie-hamilton/" target="_blank"><img src={require('../images/linkedin.png')} alt="GitHub Icon" width="35" height ="35"/></a>
                            <a href="https://github.com/RobHam99" target="_blank"><img src={require('../images/twitter.png')} alt="GitHub Icon" width="35" height ="35"/></a>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <Terminal />
                </div>
                
            </div>
        </main>
    );
}