export default function TerminalCommands() {
    return (
        <div id="terminal-commands">
            <h2>Terminal Commands</h2>
            <p>I built this terminal as something fun to play around with on the website. It mimics a real terminal window from MacOS. There are a few commands you can enter to learn more about me, and the technologies I use!</p>
            <ul>
                <li><b>help</b>: provides a list of the current terminal commands.</li>
                <li><b>bio</b>: learn more about me!</li>
                <li><b>technologies</b>: enter this command for a list of the technologies I use to build websites.</li>
                <li><b>clear</b>: clear the current terminal entries.</li>
            </ul>
            <p id="hint"><i>Hint: Try out the easter egg command for a fun secret!</i></p>
        </div>
    );
}